<template>
  <b-container class="blog">
    <!-- <NewsComponent class="MainCard" :range="65" :news-data="firstNew"></NewsComponent> -->
    <b-button-group size="md">
      <template v-for="tag in tags">
        <b-button
          :key="tag.index"
          variant="outline-primary"
          @click="TagNews(tag)"
          class="font-bold mx-1 my-1 px-5 rounded"
        >{{ tag.name }}</b-button>
      </template>
    </b-button-group>
    <!-- <b-row class="mx-auto mb-5 w-75">
      <span
        class="ColumnCards mx-auto"
        v-for="tag in tags"
        :key="tag.index"
        lg="12"
        md="12"
      >
        <b-button
          variant="outline-primary"
          @click="TagNews(tag)"
          class="font-bold"
          >{{ tag.name }}</b-button
        >
      </span>
    </b-row>-->
    <section v-if="blogs.length > 0">
      <b-row>
        <b-col
          class="ColumnCards mx-auto"
          v-for="ColNews in blogs"
          :key="ColNews.index"
          lg="8"
          md="12"
        >
          <NewsComponent :range="65" :news-data="ColNews"></NewsComponent>
        </b-col>
      </b-row>
    </section>
    <section v-else>
      <b-row>
        <h3 class="font-regular text-center">{{ $t("heading.loading") }}</h3>
      </b-row>
    </section>
    <div class="overflow-auto">
      <b-pagination
        align="center"
        size="md"
        v-model="meta.current_page"
        :total-rows="meta.total"
        :per-page="meta.per_page"
        hide-goto-end-buttons
        pills
        @change="PaginationNews"
      ></b-pagination>

    </div>
  </b-container>
</template>

<script>
import { IndexData } from "@/helpers/apiMethods";

export default {
  components: {
    NewsComponent: () => import("@/components/NewsComponent")
  },
  data() {
    return {
      tags: {},
      blogs: [],
      meta: {}
      // firstNew: {}
    };
  },
  created() {
    this.fetchBlogs();
    this.FetchTags();
  },
  methods: {
    async fetchBlogs() {
      try {
        const page = this.$route.query.page || 1;
        const { data } = await IndexData({ reqName: "news", query: { page } });
        this.meta = data.meta;
        this.blogs = data.news;
      
      } catch (err) {
        console.log(err);
      }
    },
    async FetchTags() {
      try {
        const {
          data: { tags }
        } = await IndexData({ reqName: "tags?model=news&pagination=all" });
        this.tags = tags;
      } catch (err) {
        console.log(err);
      }
    },

    async TagNews(TagSlug) {
      try {
        const {
          data: { news }
        } = await IndexData({ reqName: `news?tags=${TagSlug.name}` });
        this.blogs = news;
      } catch (err) {
        console.log(err);
      }
    },
    PaginationNews(page) {
      this.$router.push({ query: { page } });
      this.fetchBlogs();
    }
  }
};
</script>
